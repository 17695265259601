<!-- 门店管理-会员管理 -->

<script setup></script>

<template>
  <div id="doorMember">
    <button-router></button-router>
    <router-view v-slot="{ Component }">
      <!-- <keep-alive> -->
        <component :is="Component"></component>
      <!-- </keep-alive> -->
    </router-view>
  </div>
</template>

<style lang="scss" scoped>
#doorMember {
  height: 100%;
}
</style>
